<template>
  <div>
    <h1>{{ data.title }}</h1>
    <div class="intro">
      <p>
        {{ data.description }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "intro",
  data() {
    return {};
  },
  props: {
    data: Object,
    slug: String
  },
  methods: {}
};
</script>

<style lang="scss" scoped></style>
